
import {
	Component, Vue
}
	from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSales from "@/components/sidebar-sales.vue"; // @ is an alias to /src
import SaleService from "@/services/sale-service"; @
	Component({
		components: {
			Header,
			SidebarSales,
		},
	})
export default class GenerateInvoice extends Vue {
	project = null;
	invoice_type = null;

	trans: any = {};
	loader = false;
	option_crccd = ['CRCCD', 'WRDD', 'WRDD-Y', 'ERDD J GP Dep'];
	option_invoices = ['Progress Service Order', 'Final Payment', 'Partial Payment', 'Retention', 'Other']
	option_banks = ['Riyadh Bank'];
	options_project = [
		'Aramco-Riyadh',
		'Aramco-YANBU',
		'Aramco-JUAYMAH',
		'Aramco-Jeddah',
		'Yasref',
		'Marafiq',
		'KJO',
		'Bahria',
		'Bahria-New',
		'Grain-Sailos',
		'Tanajeeb',
		'Milling-company',
		'Air-fueling',
		'Air-fueling-Makkah',
		'Air-fueling-Riyadh',
		'Air-fueling-Shirqiya',
		'Air-fueling-Aseer',
		'Tameed',
		'Tameed-MOD',
		'GEMS',
		'Authentix Limited Co',
		'AlArji',
		'Mastoura',
		'HVAC-Maintenance-crdd',
		'Tameed-Turkistani',
		'Sadeem',
		'Secico',
		'Bahria-New-2023',
		'Replacement-of-Switching-Devices-KFIA',
		'Fuel-Transfer-Cart-KFAA'
	];
	option_customers = [
		'SAUDI ARABIAN OIL COMPANY',
		'YANBU ARAMCO SINOPEC REFINING COMPANY Ltd. (YASREF)',
		'Marafiq',
		'Tanajeeb',
		'KJO',
		'Bahria',
		'Grain-Sailos',
		'Al-Ahsa',
		'Qaseem',
		'Tabuk',
		'Jeddah',
		'Al-Kharj',
		'Al-Dammam',
		'Medina-Munawara',
		'Al-Riyadh',
		'Jizan',
		'Hail',
		'Jamoom',
		'Al-Jouf',
		'Khamis Musheet',
		'Air-fueling',
		'Tameed',
		'Tameed Ministory Of Defence',
		'Tameed Madina Munawwara',
		'GEMS',
		'Authentix Limited Co',
		'AlArji',
		'Mastoura',
		'HVAC-Maintenance-crdd',
		'Turkistani Tameed',
		'Sadeem Business Trading Company (one person company)',
		'Al Ahsa Tameed',
		'TAMEED RIYADH MILLS',
		'Secico',
		'Aljhalami Company',
		'Tameed Tabuk',
		'Tameed Jizan'
	];
	option_covered_period: any = [];
	items = [{
		sno: 1,
		item: "",
		item_arabic: "",
		datehijri: '',
		dategeo: '',
		unit: 0,
		price: 0,
		req_quantity: 0,
		quantity: 0,
		amount: 0,
		absent: 0,
		absent_deduction: 0
	}];

	public contract_start = "";
	public contract_end = "";
	public invoice_start = "";
	public invoice_end = "";
	public payment_due = "";

	changedValue() { }
	change_project() { }
	changedValueInvoice() { }
	onchange(index: any, value: any) {
		this.items[index].absent_deduction = Math.round((value.absent * value.price + Number.EPSILON) * 100) / 100
		this.items[index].amount = (Math.round((value.quantity * value.price + Number.EPSILON) * 100) / 100) - this.items[index].absent_deduction;
		// this.obj_sum.net_amount = this.items.reduce((total:any , obj:any) => obj.amount + total,0)
		// this.trans.total_amount = this.roundoff(this.obj_sum.net_amount);		
	}
	saveinvoice() {
		this.loader = true;
		this.trans['project'] = this.project;
		this.trans['contract_start'] = this.contract_start;
		this.trans['contract_end'] = this.contract_end;
		this.trans['invoice_start'] = this.invoice_start;
		this.trans['invoice_end'] = this.invoice_end;
		this.trans['payment_due'] = this.payment_due;
		// if(this.trans.amount_words_ar){

		// }

		if (this.invoice_type == 'Other') {
			this.trans['invoice_type'] = this.trans.invoice_others;
		} else {
			this.trans['invoice_type'] = this.invoice_type;
		}
		let obj = {
			invoice: this.trans,
			items: this.items
		}
		SaleService.create(obj).then((response) => {
			let data = response.data;
			this.loader = false;
			if (this.project == 'Aramco-Riyadh') {
				this.$router.push({ path: `/invoice/sales-invoice-riyadh/${data.invoice}` });
			} else if (this.project == 'Aramco-YANBU') {
				this.$router.push({ path: `/invoice/sales-invoice-yumbu/${data.invoice}` });
			}
			else if (this.project == 'Aramco-JUAYMAH') {
				this.$router.push({ path: `/invoice/sales-invoice-juaymah/${data.invoice}` });
			}
			else if (this.project == 'Aramco-Jeddah') {
				this.$router.push({ path: `/invoice/sales-invoice-jeddah/${data.invoice}` });
			} else if (this.project == 'Yasref') {
				this.$router.push({ path: `/invoice/sales-invoice-yasref/${data.invoice}` });
			} else if (this.project == 'Marafiq') {
				this.$router.push({ path: `/invoice/sales-invoice-marafiq/${data.invoice}` });
			}
			else if (this.project == 'KJO') {
				this.$router.push({ path: `/invoice/sales-invoice-kjo/${data.invoice}` });
			}
			else if (this.project == 'Tanajeeb') {
				this.$router.push({ path: `/invoice/sales-invoice-tanajeeb/${data.invoice}` });
			}
			else if (this.project == 'Bahria') {
				this.$router.push({ path: `/invoice/sales-invoice-bahria/${data.invoice}` });
			}
			else if (this.project == 'Bahria-New') {
				this.$router.push({ path: `/invoice/sales-invoice-bahria-new/${data.invoice}` });
			}
			else if (this.project == 'Grain-Sailos') {
				this.$router.push({ path: `/invoice/sales-invoice-grain-sailos/${data.invoice}` });
			}
			else if (this.project == 'Milling-company') {
				this.$router.push({ path: `/invoice/sales-invoice-milling/${data.invoice}` });
			}
			else if (this.project == 'Air-fueling-Makkah') {
				this.$router.push({ path: `/invoice/sales-invoice-air-fueling-makkah/${data.invoice}` });
			}
			else if (this.project == 'Air-fueling-Riyadh') {
				this.$router.push({ path: `/invoice/sales-invoice-air-fueling-riyadh/${data.invoice}` });
			}
			else if (this.project == 'Air-fueling-Shirqiya') {
				this.$router.push({ path: `/invoice/sales-invoice-air-fueling-shirqiya/${data.invoice}` });
			}
			else if (this.project == 'Air-fueling-Aseer') {
				this.$router.push({ path: `/invoice/sales-invoice-air-fueling-aseer/${data.invoice}` });
			}
			else if (this.project == 'Tameed') {
				this.$router.push({ path: `/invoice/sales-invoice-tameed/${data.invoice}` });
			}
			else if (this.project == 'Tameed-MOD') {
				this.$router.push({ path: `/invoice/sales-invoice-tameed-mod/${data.invoice}` });
			}
			else if (this.project == 'GEMS') {
				this.$router.push({ path: `/invoice/sales-invoice-aramco-gmc/${data.invoice}` });
			}
			else if (this.project == 'Mastoura') {
				this.$router.push({ path: `/invoice/sales-invoice-aramco-mastoura/${data.invoice}` });
			}

			else if (this.project == 'Authentix Limited Co') {
				this.$router.push({ path: `/invoice/sales-invoice-authentix/${data.invoice}` });
			}
			else if (this.project == 'AlArji') {
				this.$router.push({ path: `/invoice/sales-invoice-alarji/${data.invoice}` });
			}
			else if (this.project == 'HVAC-Maintenance-crdd') {
				this.$router.push({ path: `/invoice/sales-invoice-HVAC-Maintenance-crdd/${data.invoice}` });
			}
			else if (this.project == 'Tameed-Turkistani') {
				this.$router.push({ path: `/invoice/sales-invoice-tameed-turkistani/${data.invoice}` });
			}
			else if (this.project == 'Sadeem') {
				this.$router.push({ path: `/invoice/sales-invoice-sadeem-business/${data.invoice}` });
			}
			else if (this.project == 'Secico') {
				this.$router.push({ path: `/invoice/sales-invoice-sceco/${data.invoice}` });
			}
			else if (this.project == 'Bahria-New-2023') {
				this.$router.push({ path: `/invoice/sales-invoice-bahria-new-2023/${data.invoice}` });
			}
			else if (this.project == 'Replacement-of-Switching-Devices-KFIA') {
				this.$router.push({ path: `/invoice/sales-invoice-replacement-of-switching-devices-kfia/${data.invoice}` });
			}
			else if (this.project == 'Fuel-Transfer-Cart-KFAA') {
				this.$router.push({ path: `/invoice/sales-invoice-fuel-transfer-cart-kfaa/${data.invoice}` });
			}
		}).catch((e) => {
			this.loader = false;
			console.log(e);
		});
	}
	add_item() {
		this.items.push({
			sno: this.items.length + 1,
			item: "",
			item_arabic: "",
			datehijri: "",
			dategeo: "",
			price: 0,
			unit: 0,
			req_quantity: 0,
			quantity: 0,
			amount: 0,
			absent: 0,
			absent_deduction: 0
		});
	}
	getsetting() {
		SaleService.getcovered().then((response: any) => {
			// this.option_covered_period = response.data;
			response.data.forEach((x: any) => {
				let obj: any = {
					value: x.id,
					text: x.item
				}
				this.option_covered_period.push(obj)

			});
			// this.loader = false;
			// this.$router.push({ path: `/invoice/sales-invoice/${data.invoice}` });
		}).catch((e: any) => {
			this.loader = false;
			console.log(e);
		});
	}
	mounted() {
		this.getsetting()
	}
	deleteRow(index: any) {
		this.items.splice(index, 1);
	}
}
